




















































































































































































































































import { debounceProcess } from "@/helpers/debounce";
import { Row, useBlob, useDate, useReportValuation } from "@/hooks";
import { FilterField } from "@/hooks/useReportValuation";
import { RequestQueryParams } from "@/models/class/request-query-params.class";
import { PAGE_SIZE_OPTIONS } from "@/models/constant/global.constant";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { Messages } from "@/models/enums/messages.enum";
import {
  DataListProduct,
  DataWarehouse,
  DataWarehouseBranch,
  DataWarehouseLocation,
} from "@/models/interface/logistic.interface";
import {
  DataListProductCategory,
  IProductCategory,
} from "@/models/interface/product.interface";
import {
  HeadStockValuationReportCreateDTO,
  StockValuationReportCreateDTO,
} from "@/models/interface/StockValuationReport.interface";
import { logisticServices } from "@/services/logistic.service";
import { productService } from "@/services/product.service";
import Vue from "vue";

export default Vue.extend({
  name: "ReportValuation",
  data() {
    this.getProductCode = debounceProcess(this.getProductCode, 500);
    this.getProductName = debounceProcess(this.getProductName, 500);
    this.getProductCategory = debounceProcess(this.getProductCategory, 500);
    this.getListLocationBranch = debounceProcess(
      this.getListLocationBranch,
      500
    );
    this.getListWarehouse = debounceProcess(this.getListWarehouse, 500);
    this.getListLocationRack = debounceProcess(this.getListLocationRack, 500);
    return {
      PAGE_SIZE_OPTIONS,
      DEFAULT_DATE_FORMAT,
      form: this.$form.createForm(this, { name: "formReportValuation" }),
      dataSource: [] as Row<StockValuationReportCreateDTO>[],
      dataReport: {
        grandTotalValuation: 0,
        grandTotalOnHand: 0,
        data: [],
      } as HeadStockValuationReportCreateDTO,

      idWarehouse: "",
      idBranch: "",
      productCategory: "",
      productCode: "",
      productName: "",
      dataProductCode: [] as DataListProduct[],
      dataProductName: [] as DataListProduct[],
      dataCategory: [] as IProductCategory[],
      loadingProductCategory: false,
      loadingProductCode: false,
      loadingProductName: false,
      loadingLocationWarehouse: false,
      loadingLocationBranch: false,
      loadingFind: false,
      loadingDownload: false,
      loadingPrint: false,
      dataListLocation: [] as DataWarehouseBranch[],
      dataListLocationWarehouse: [] as DataWarehouse[],
      dataListLocationRack: [] as DataWarehouseLocation[],
      columnsCogsHistories: [
        {
          title: this.$t("lbl_cogs_date"),
          dataIndex: "cogsDate",
          key: "cogsDate",
          scopedSlots: { customRender: "date" },
        },
        {
          title: this.$t("lbl_on_hand"),
          dataIndex: "onHand",
          key: "onHand",
          scopedSlots: { customRender: "number" },
        },
        {
          title: this.$t("lbl_cogs"),
          dataIndex: "cogsValue",
          key: "cogsValue",
          scopedSlots: { customRender: "currency" },
        },

        {
          title: this.$t("lbl_total"),
          dataIndex: "total",
          key: "total",
          scopedSlots: { customRender: "currency" },
        },
      ],
      columnsTable: [
        {
          title: this.$t("lbl_part_category"),
          dataIndex: "productCategory",
          key: "productCategory",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_part_number"),
          dataIndex: "productCode",
          key: "productCode",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_part_name"),
          dataIndex: "productName",
          key: "productName",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_location"),
          dataIndex: "location",
          key: "productLocation",
          scopedSlots: { customRender: "nullable" },
        },
        {
          title: this.$t("lbl_total_on_hand"),
          dataIndex: "totalOnHand",
          key: "totalOnHand",
          align: "right",
          scopedSlots: { customRender: "number" },
        },
        {
          title: this.$t("lbl_valuations"),
          dataIndex: "valuationValue",
          key: "valuationValue",
          align: "right",
          scopedSlots: { customRender: "currency" },
        },
      ],
      formRules: {
        productCode: {
          label: "lbl_part_number",
          name: "productCode",
          placeholder: "lbl_part_number_placeholder",
          decorator: ["productCode"],
        },
        productName: {
          label: "lbl_part_name",
          name: "productName",
          placeholder: "lbl_part_name_placeholder",
          decorator: ["productName"],
        },
        productCategory: {
          label: "lbl_part_category",
          name: "productCategory",
          placeholder: "lbl_part_category_placeholder",
          decorator: ["productCategoryId"],
        },
        asOfDate: {
          label: "lbl_as_of_date",
          name: "asOfDate",
          placeholder: "lbl_as_of_date_placeholder",
          decorator: [
            "asOfDate",
            {
              rules: [
                {
                  required: true,
                  message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
                },
              ],
            },
          ],
        },
        branch: {
          label: "lbl_branch",
          name: "branch",
          placeholder: "lbl_branch_placeholder",
          decorator: ["branchId"],
        },
        warehouse: {
          label: "lbl_warehouse",
          name: "warehouse",
          placeholder: "lbl_warehouse_placeholder",
          decorator: ["warehouseId"],
        },
        rack: {
          label: "lbl_rack",
          name: "rack",
          placeholder: "lbl_rack_placeholder",
          decorator: ["rackId"],
        },
      },
    };
  },
  created() {
    this.getProductCode("");
    this.getProductCategory("");
    this.getListLocationBranch("");
    this.getProductName("");
  },
  methods: {
    handleChangeProductCategory(val: { key: string; label: string }) {
      this.productCategory = val?.key ?? "";
      this.getProductName("");
      this.getProductCode("");
    },
    handleProductCode(code) {
      this.productCode = code;
      this.dataProductCode.forEach(dataForeach => {
        if (dataForeach.code === code) {
          this.form.setFieldsValue({
            productName: dataForeach.name,
            productCategory: dataForeach.categoryName,
          });
        }
        const temp: any = { name: dataForeach.categoryName };
        this.dataCategory = [...this.dataCategory, temp].filter(
          (dataFilter, index, self) =>
            index === self.findIndex(t => t.name === dataFilter.name)
        );
      });
      this.getProductName("");
    },
    handleChangeLocationBranch(id) {
      this.idBranch = id;
      this.idWarehouse = "";
      this.form.resetFields(["warehouseId", "rackId"]);
      this.getListWarehouse("", this.idBranch);
    },
    handleChangeWarehouse(id) {
      this.idWarehouse = id;
      this.form.resetFields(["rackId"]);
      this.getListLocationRack("", id);
    },
    getListWarehouse(valueSearch, id) {
      if (id) {
        const params = new RequestQueryParams();
        params.search = `branchWarehouse.secureId~${id}`;
        if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
        this.loadingLocationWarehouse = true;
        logisticServices
          .listWarehouse(params, "")
          .then(data => {
            this.dataListLocationWarehouse = data.data;
          })
          .finally(() => (this.loadingLocationWarehouse = false));
      } else {
        this.$notification.error({
          message: "Error",
          description: "Choose Location Branch first to get list warehouse",
        });
      }
    },
    getListLocationRack(valueSearch, id) {
      if (id) {
        const params = new RequestQueryParams();
        params.search = `warehouse.secureId~${id}`;
        if (valueSearch) params.search += `_AND_name~*${valueSearch}*`;
        this.loadingLocationBranch = true;
        logisticServices
          .listWarehouseLocation(params, "")
          .then(data => {
            this.dataListLocationRack = data.data;
          })
          .finally(() => (this.loadingLocationBranch = false));
      } else {
        this.$notification.error({
          message: "Error",
          description: "Choose Location Warehouse first to get list rack",
        });
      }
    },
    getListLocationBranch(valueSearch) {
      const params = new RequestQueryParams();
      if (valueSearch)
        params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
      this.loadingLocationBranch = true;
      logisticServices
        .listWarehouseBranch(params, "")
        .then(data => {
          this.dataListLocation = data.data;
        })
        .finally(() => (this.loadingLocationBranch = false));
    },
    handleChangeProductName(name) {
      this.productName = name;
      const product = this.dataProductName.find(e => e.name === name);
      this.form.setFieldsValue({
        productCode: product?.code || "",
        productCategory: product?.categoryName || "",
      });
      const temp: any = { name: product?.categoryName || "" };
      this.dataCategory = [...this.dataCategory, temp].filter(
        (dataFilter, index, self) =>
          index === self.findIndex(t => t.name === dataFilter.name)
      );
      this.getProductCode("");
    },
    getProductName(valueSearch): void {
      const params = new RequestQueryParams();
      if (this.productCode) params.search = `code~${this.productCode}`;

      if (this.productCategory && params.search)
        params.search += `_AND_category.secureId~${this.productCategory}`;
      else if (this.productCategory)
        params.search = `category.secureId~${this.productCategory}`;

      if (valueSearch && params.search)
        params.search += `_AND_name~*${valueSearch}*`;
      else if (valueSearch) params.search = `name~*${valueSearch}*`;

      this.loadingProductName = true;
      logisticServices
        .listProduct(params)
        .then(data => {
          this.dataProductName = data.data;
        })
        .finally(() => (this.loadingProductName = false));
    },
    getProductCategory(valueSearch): void {
      this.loadingProductCategory = true;
      const params = new RequestQueryParams();
      if (valueSearch) params.search = `name~*${valueSearch}*`;
      productService
        .listProductCategory(params)
        .then((res: DataListProductCategory) => {
          this.dataCategory = res.data;
        })
        .finally(() => (this.loadingProductCategory = false));
    },
    getProductCode(valueSearch): void {
      const params = new RequestQueryParams();

      if (this.productName) params.search = `name~${this.productName}`;

      if (this.productCategory && params.search)
        params.search += `_AND_category.secureId~${this.productCategory}`;
      else if (this.productCategory)
        params.search = `category.secureId~${this.productCategory}`;

      if (valueSearch && params.search)
        params.search += `_AND_code~*${valueSearch}*`;
      else if (valueSearch) params.search = `code~*${valueSearch}*`;
      this.loadingProductCode = true;
      logisticServices
        .listProduct(params)
        .then(data => {
          this.dataProductCode = data.data;
        })
        .finally(() => (this.loadingProductCode = false));
    },
    async handleDownload(field: FilterField): Promise<void> {
      const { download, buildParam } = useReportValuation();
      const { toDefaultFormat } = useDate();
      const { toDownload } = useBlob();

      this.loadingDownload = true;

      const params = new RequestQueryParams();
      params.categoryId = field.productCategoryId ?? null;
      params.productCode = field.productCode ?? null;
      params.locationId = field.rackId ?? null;
      params.warehouseId = field.warehouseId ?? null;
      params.branchId = field.branchId ?? null;
      params.params = await buildParam(field);

      const response = await download(toDefaultFormat(field.asOfDate), params);
      toDownload(response, "stock_report_valuation_report.xlsx");
      this.loadingDownload = false;
    },
    handleFind(fields: FilterField): void {
      const { findAll } = useReportValuation();
      const { toDefaultFormat } = useDate();
      const params = new RequestQueryParams();

      params.categoryId = fields.productCategoryId ?? null;
      params.productCode = fields.productCode ?? null;
      params.locationId = fields.rackId ?? null;
      params.warehouseId = fields.warehouseId ?? null;
      params.branchId = fields.branchId ?? null;

      const date = toDefaultFormat(fields.asOfDate);

      this.loadingFind = true;
      findAll(date, params)
        .then(response => {
          this.dataReport = response;
          this.dataSource = this.dataReport.data.map((item, i) => ({
            ...item,
            key: i,
          }));
        })
        .finally(() => {
          this.loadingFind = false;
        });
    },
    validateForm(type: "find" | "download" | "print"): void {
      this.form.validateFields((err, values) => {
        if (err) return;

        if (type === "find") {
          this.handleFind({
            ...values,
            productCategoryId: values.productCategoryId?.key ?? "",
          });
        } else if (type === "download") {
          this.handleDownload({
            ...values,
            productCategoryId: values.productCategoryId?.key ?? "",
            productCategory: values.productCategoryId?.label ?? "",
          });
        }
      });
    },
    handleReset(): void {
      this.form.resetFields();
      this.idBranch = "";
      this.idWarehouse = "";
      this.productCategory = "";
      this.productCode = "";
      this.productName = "";
    },
  },
});
