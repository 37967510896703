var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.$t("lbl_valuation_stock_report") } },
    [
      _c(
        "a-form",
        {
          attrs: {
            "wrapper-col": { span: 12 },
            "label-col": { span: 5 },
            form: _vm.form,
            "label-align": "left"
          },
          nativeOn: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.validateForm("find")
            }
          }
        },
        [
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    { attrs: { label: _vm.$t(_vm.formRules.asOfDate.label) } },
                    [
                      _c("a-date-picker", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: _vm.formRules.asOfDate.decorator,
                            expression: "formRules.asOfDate.decorator"
                          }
                        ],
                        staticClass: "w-100",
                        attrs: {
                          name: _vm.formRules.asOfDate.name,
                          placeholder: _vm.$t(
                            _vm.formRules.asOfDate.placeholder
                          ),
                          format: _vm.DEFAULT_DATE_FORMAT
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.productCategory.label)
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.productCategory.decorator,
                              expression: "formRules.productCategory.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.productCategory.name,
                            placeholder: _vm.$t(
                              _vm.formRules.productCategory.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingProductCategory,
                            allowClear: true,
                            "label-in-value": ""
                          },
                          on: {
                            search: function(value) {
                              return _vm.getProductCategory(value)
                            },
                            change: _vm.handleChangeProductCategory
                          }
                        },
                        _vm._l(_vm.dataCategory, function(data) {
                          return _c(
                            "a-select-option",
                            { key: data.id, attrs: { value: data.id } },
                            [_vm._v(" " + _vm._s(data.name) + " ")]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.productCode.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.productCode.decorator,
                              expression: "formRules.productCode.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.productCode.name,
                            placeholder: _vm.$t(
                              _vm.formRules.productCode.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingProductCode,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getProductCode(value)
                            },
                            change: _vm.handleProductCode
                          }
                        },
                        _vm._l(_vm.dataProductCode, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.code } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.code) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.code) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: { label: _vm.$t(_vm.formRules.productName.label) }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.productName.decorator,
                              expression: "formRules.productName.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.productName.name,
                            placeholder: _vm.$t(
                              _vm.formRules.productName.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingProductName,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getProductName(value)
                            },
                            change: _vm.handleChangeProductName
                          }
                        },
                        _vm._l(_vm.dataProductName, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.name } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.branch.label),
                        "label-align": "right"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.branch.decorator,
                              expression: "formRules.branch.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.branch.name,
                            placeholder: _vm.$t(
                              _vm.formRules.branch.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingLocationBranch,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListLocationBranch(value)
                            },
                            change: _vm.handleChangeLocationBranch
                          }
                        },
                        _vm._l(_vm.dataListLocation, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s("" + data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s("" + data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.warehouse.label),
                        "label-align": "right"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.warehouse.decorator,
                              expression: "formRules.warehouse.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.warehouse.name,
                            placeholder: _vm.$t(
                              _vm.formRules.warehouse.placeholder
                            ),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingLocationWarehouse,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListWarehouse(value, _vm.idBranch)
                            },
                            change: _vm.handleChangeWarehouse
                          }
                        },
                        _vm._l(_vm.dataListLocationWarehouse, function(
                          data,
                          index
                        ) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-form-item",
                    {
                      attrs: {
                        label: _vm.$t(_vm.formRules.rack.label),
                        "label-align": "right"
                      }
                    },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: _vm.formRules.rack.decorator,
                              expression: "formRules.rack.decorator"
                            }
                          ],
                          attrs: {
                            name: _vm.formRules.rack.name,
                            placeholder: _vm.$t(_vm.formRules.rack.placeholder),
                            showSearch: true,
                            "filter-option": false,
                            loading: _vm.loadingProductCode,
                            allowClear: true
                          },
                          on: {
                            search: function(value) {
                              return _vm.getListLocationRack(
                                value,
                                _vm.idWarehouse
                              )
                            }
                          }
                        },
                        _vm._l(_vm.dataListLocationRack, function(data, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: data.id } },
                            [
                              _c(
                                "a-tooltip",
                                [
                                  _c("template", { slot: "title" }, [
                                    _vm._v(" " + _vm._s(data.name) + " ")
                                  ]),
                                  _vm._v(" " + _vm._s(data.name) + " ")
                                ],
                                2
                              )
                            ],
                            1
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { gutter: [16, 16] } },
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-space",
                    [
                      _c("a-button", { on: { click: _vm.handleReset } }, [
                        _vm._v(" " + _vm._s(_vm.$t("lbl_reset")) + " ")
                      ]),
                      _c(
                        "a-button",
                        {
                          attrs: {
                            type: "primary",
                            "html-type": "submit",
                            loading: _vm.loadingFind
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t("lbl_find")) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("a-table", {
        attrs: {
          dataSource: _vm.dataSource,
          columns: _vm.columnsTable,
          pagination: {
            showTotal: function(total) {
              return _vm.$t("lbl_total_items", { total: total })
            },
            pageSizeOptions: _vm.PAGE_SIZE_OPTIONS,
            showSizeChanger: true
          },
          loading: _vm.loadingFind,
          size: "small"
        },
        scopedSlots: _vm._u([
          {
            key: "currency",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("currency")(text)))])
            }
          },
          {
            key: "number",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("qty")(text)))])
            }
          },
          {
            key: "date",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(_vm._f("date")(text)))])
            }
          },
          {
            key: "nullable",
            fn: function(text) {
              return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
            }
          },
          {
            key: "expandedRowRender",
            fn: function(record) {
              return [
                _c("a-table", {
                  attrs: {
                    columns: _vm.columnsCogsHistories,
                    "data-source": record.cogsHistories,
                    size: "small",
                    "row-key": function(r, i) {
                      return i
                    }
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "currency",
                        fn: function(text) {
                          return _c("span", {}, [
                            _vm._v(_vm._s(_vm._f("currency")(text)))
                          ])
                        }
                      },
                      {
                        key: "number",
                        fn: function(text) {
                          return _c("span", {}, [
                            _vm._v(_vm._s(_vm._f("qty")(text)))
                          ])
                        }
                      },
                      {
                        key: "date",
                        fn: function(text) {
                          return _c("span", {}, [
                            _vm._v(_vm._s(_vm._f("date")(text)))
                          ])
                        }
                      },
                      {
                        key: "nullable",
                        fn: function(text) {
                          return _c("span", {}, [_vm._v(_vm._s(text || "-"))])
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              ]
            }
          },
          {
            key: "footer",
            fn: function() {
              return [
                _c(
                  "a-descriptions",
                  { attrs: { size: "small" } },
                  [
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_grand_total_on_hand") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.dataReport.grandTotalOnHand
                              )
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "a-descriptions-item",
                      { attrs: { label: _vm.$t("lbl_grand_total_valuation") } },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.dataReport.grandTotalValuation
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c(
        "div",
        { staticClass: "text-right mt-2" },
        [
          _c(
            "a-space",
            [
              _c(
                "a-button",
                {
                  attrs: { loading: _vm.loadingDownload, type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.validateForm("download")
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_download")) + " ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }